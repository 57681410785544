import { useTranslations } from 'next-intl';
import Link from 'next/link';

import { LINKS, getLink } from '@/utils/getLink';
import { Button, Icon } from '../atoms';

function Enterprises() {
  const t = useTranslations();

  return (
    <div className="w-full bg-bg-body">
      <div className="container flex flex-col gap-6 py-10 sm:gap-10">
        <h2 className="h1 text-center">{t('forEnterprises')}</h2>
        <div className="flex flex-col justify-center gap-4 lg:flex-row lg:gap-6">
          <div className="flex w-full flex-col gap-4 sm:w-[22.5rem]">
            <div className="flex items-center gap-2">
              <Icon
                name="success"
                className="rounded-lg bg-light-blue-700 text-fg-inverse-default"
              />
              <h4 className="subtitle2-regular">{t('eProcurementPunchoutEnabled')}</h4>
            </div>
            <div className="flex items-center gap-2">
              <Icon
                name="success"
                className="rounded-lg bg-light-blue-700 text-fg-inverse-default"
              />
              <h4 className="subtitle2-regular">{t('singleSignOn')}</h4>
            </div>
            <div className="flex items-center gap-2">
              <Icon
                name="success"
                className="rounded-lg bg-light-blue-700 text-fg-inverse-default"
              />
              <h4 className="subtitle2-regular">{t('adminUserSetup')}</h4>
            </div>
            <div className="flex items-center gap-2">
              <Icon
                name="success"
                className="rounded-lg bg-light-blue-700 text-fg-inverse-default"
              />
              <h4 className="subtitle2-regular">{t('spendManagement')}</h4>
            </div>
          </div>

          <div className="flex w-full flex-col gap-3 sm:w-[22.5rem]">
            <div className="flex items-center gap-2">
              <Icon
                name="success"
                className="rounded-lg bg-light-blue-700 text-fg-inverse-default"
              />
              <h4 className="subtitle2-regular">{t('invoiceAccountWithPriorApproval')}</h4>
            </div>
            <div className="flex items-center gap-2">
              <Icon
                name="success"
                className="rounded-lg bg-light-blue-700 text-fg-inverse-default"
              />
              <h4 className="subtitle2-regular">{t('zeroSubscriptionFees')}</h4>
            </div>
            <div className="flex items-center gap-2">
              <Icon
                name="success"
                className="rounded-lg bg-light-blue-700 text-fg-inverse-default"
              />
              <h4 className="subtitle2-regular">{t('dedicatedAccountManager')}</h4>
            </div>
            <div className="flex items-center gap-2">
              <Icon
                name="success"
                className="rounded-lg bg-light-blue-700 text-fg-inverse-default"
              />
              <h4 className="subtitle2-regular">{t('vipSupport')}</h4>
            </div>
          </div>
        </div>
        <div className="flex flex-col justify-center gap-4 sm:flex-row">
          <Link href={LINKS.signUp} prefetch={false} shallow={true}>
            <Button color="secondary" className="w-full sm:w-fit">
              {t('createAFreeAccount')}
            </Button>
          </Link>
          <Link href={getLink('contactUs')} prefetch={false} shallow={true}>
            <Button color="secondary" variant="outlined" className="w-full sm:w-fit">
              {t('talkToAnExpert')}
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export { Enterprises };
